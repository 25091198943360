// .ENV
const ROOT_URL = process.env.REACT_APP_BACKEND

export async function smsUser(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(loginPayload),
  }

  try {
    dispatch({ type: 'REQUEST_LOGIN' })
    let response = await fetch(`${ROOT_URL}/auth/token`, requestOptions)
    let data = await response.json()

    if (data) {
      dispatch({ type: 'SMS_SUCCESS', payload: data })
      localStorage.setItem('currentUser', JSON.stringify(data))
      return data
    }

    dispatch({ type: 'LOGIN_ERROR', error: data.errors[0] })
    return
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error })
  }
}

export async function vkUser(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(loginPayload),
  }

  try {
    dispatch({ type: 'REQUEST_LOGIN' })
    let response = await fetch(`${ROOT_URL}/auth/vk`, requestOptions)
    let data = await response.json()
    if (data.errors) {
      sessionStorage.removeItem('verify')
      sessionStorage.removeItem('state')
      window.location.search = ''
      return dispatch({ type: 'LOGIN_ERROR', error: data.errors[0] })
    }
    if (data) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: data })
      localStorage.setItem('currentUser', JSON.stringify(data))
      return data
    }
  } catch (error) {
    sessionStorage.removeItem('verify')
    sessionStorage.removeItem('state')
    window.location.search = ''
    dispatch({ type: 'LOGIN_ERROR', error: error })
  }

}

export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Accept: 'applicaton/json ' },
    body: JSON.stringify(loginPayload),
  }

  try {
    dispatch({ type: 'REQUEST_LOGIN' })
    let response = await fetch(`${ROOT_URL}/auth/token`, requestOptions)
    let data = await response.json()
    if (data && data.user) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: data })
      localStorage.setItem('currentUser', JSON.stringify(data))
      return data
    }

    dispatch({ type: 'LOGIN_ERROR', error: data.errors[0] })
    return data
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error })
  }
}

export async function updateUser(dispatch, updatePayload) {
  try {
    dispatch({
      type: 'UPDATE_USER',
      payload: updatePayload,
    })
    localStorage.setItem(
      'currentUser',
      JSON.stringify({
        access_token: JSON.parse(localStorage.getItem('currentUser'))
          .access_token,
        user: updatePayload,
        is_new: updatePayload.is_new
      }),
    )

    return updatePayload
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error })
  }
}

export async function updateToken(dispatch, updatePayload) {
  try {
    dispatch({
      type: 'UPDATE_TOKEN',
      payload: updatePayload,
    })
    return updatePayload
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error })
  }
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' })
  localStorage.removeItem('currentUser')
  localStorage.removeItem('token')
  sessionStorage.removeItem('verify')
  sessionStorage.removeItem('state')

}
