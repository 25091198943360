let user = localStorage.getItem('currentUser')
	? JSON.parse(localStorage.getItem('currentUser')).user
	: '';
let token = localStorage.getItem('currentUser')
	? JSON.parse(localStorage.getItem('currentUser')).access_token
	: '';

  let newUser = localStorage.getItem('currentUser')
	? JSON.parse(localStorage.getItem('currentUser')).is_new
	: null;
export const initialState = {
	userDetails: '' || user,
	token: token || '',
	loading: false,
  newUser : newUser,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {

	switch (action.type) {
		case 'REQUEST_LOGIN':
			return {
				...initialState,
				loading: true,
			};
		case 'LOGIN_SUCCESS':
			return {
				...initialState,
				userDetails: action.payload.user,
				token: action.payload.access_token,
				loading: false,
        newUser: action.payload.is_new
			};
		case 'UPDATE_USER':
			return {
				...initialState,
				userDetails: action.payload,
				loading: false,
			};
		case 'UPDATE_TOKEN':
			return {
				token: action.payload,
				loading: false,
			};
		case 'SMS_SUCCESS':
			return {
				...initialState,
				token: action.payload.access_token,
				loading: false,
			};
		case 'LOGOUT':
			return {
				...initialState,
				user: '',
				userDetails: '',
				token: '',
			};

		case 'LOGIN_ERROR':
			return {
				...initialState,
				loading: false,
        user: '',
				userDetails: '',
				token: '',
				errorMessage: action.error,
			};

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
