import { Formik, Form, Field } from 'formik'
import { SmartCaptcha } from '@yandex/smart-captcha'
import { InputPhone } from 'components/inputs/phoneInput'
import { MainLayout } from 'components/layout/mainLayout'
import { Bckgr } from 'blocks'
import axios from 'axios'
import { validationPhone } from '../validation'
import { useEffect, useState } from 'react'
import { Loader } from '../../loader/loader'
import BreadcrumbsProfile from 'components/breadcrumbsProfile'
import 'components/layout/index.scss'
import { scroll } from 'components/utils'
import VkBtn from '../vkBtn'

export const PhoneStep = ({
  nextStep,
  setPhone,
  setIsnew,
  setExpiredIn,
  setSmsError,
  setStep,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState('')
  const [errorSend, setErrorSend] = useState('')

  useEffect(() => {
    if (token) {
      setErrorSend('')
    }
  }, [token])

  useEffect(() => {
    scroll('#root')
  }, [])

  return (
    <MainLayout>
      <Bckgr />
      <section className="authorization">
        <div className="authorization__container">
          <h2 className="authorization__title title">
            Всероссийский конкурс гражданских инициатив
          </h2>
          <BreadcrumbsProfile />
          <div className="authorization__step1">
            <p className="authorization__desc">
              Пожалуйста, введите свой мобильный телефон —<br />
              Вам будет отправлен одноразовый пароль для входа
            </p>
            <Formik
              initialValues={{ phone: '', personal_data_confirmation: false }}
              validate={(values) => {
                return validationPhone(values)
              }}
              onSubmit={async (values) => {
                if (token) {
                  const prepPhone = values.phone
                    .replaceAll(/[+()\-\s]/g, '')
                    .substring(1)
                  setIsLoading(true)
                  return axios
                    .post(
                      `${process.env.REACT_APP_BACKEND}/auth/authenticate`,
                      {
                        token: token,
                        phone: prepPhone,
                        personal_data_confirmation:
                          values.personal_data_confirmation,
                      },
                    )
                    .then((data) => {
                      if (data.data.user) {
                        setPhone(prepPhone)
                        setExpiredIn(data.data?.expired || 5)
                        return nextStep()
                      }
                      if (data.data.expired) {
                        setExpiredIn(data.data.expired)
                        setSmsError(true)
                      }
                      setPhone(prepPhone)
                      nextStep()
                    })
                    .catch((e) => {
                      setErrorSend('Что то пошло не так...')
                    })
                    .finally(() => setIsLoading(false))
                } else {
                  setErrorSend('Ошибка проверки капчи')
                  setIsLoading(false)
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                setFieldValue,
              }) => (
                <Form
                  className="authorization__form authorization__form_2 js-authorization-form"
                  onSubmit={handleSubmit}
                >
                  <div className="authorization__input-group phone">
                    <div className="authorization__input-block">
                      <label htmlFor="phone">Номер телефона*</label>
                      <InputPhone
                        className={errors.phone ? 'input error' : 'input'}
                        onChange={(e) => {
                          if (e.target.value[4] !== '9')
                            return setFieldValue('phone', true)
                          return setFieldValue('phone', e.target.value)
                        }}
                        onBlur={handleBlur}
                        value={values.phone}
                        disabled={isLoading}
                      />
                      <span className="authorization__error">
                        {errors.phone && touched.phone && errors.phone}
                        {errorSend && errorSend}
                      </span>
                    </div>
                    <div className="authorization__input-button">
                      <button
                        className="authorization__btn-submit button"
                        type="submit"
                        disabled={!isValid || isLoading || !token}
                      >
                        {isLoading && <Loader />}
                        <span style={{ opacity: isLoading ? 0 : 1 }}>
                          Получить смс
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="authorization__input-group">
                    <div className="authorization__checkbox-block">
                      <label htmlFor="personal_data_confirmation">
                        <Field
                          id="personal_data_confirmation"
                          name="personal_data_confirmation"
                          type="checkbox"
                          onChange={handleChange}
                        />
                        <span>
                          Я даю{' '}
                          <a
                            target={'_blank'}
                            rel="noreferrer"
                            href="https://er.ru/upages/personal"
                          >
                            согласие на обработку{' '}
                          </a>
                          моих персональных данных в соответствии ФЗ №152-ФЗ «О
                          персональных данных» и на
                          <a
                            target={'_blank'}
                            rel="noreferrer"
                            href="https://er.ru/upages/privacy-policy"
                          >
                            {' '}
                            Политику конфиденциальности
                          </a>
                        </span>
                      </label>
                      <span className="authorization__error">
                        {errors.personal_data_confirmation &&
                          touched.personal_data_confirmation &&
                          errors.personal_data_confirmation}
                      </span>
                    </div>
                  </div>
                  <VkBtn setStep={setStep} setPhone={setPhone} setIsnew={setIsnew} />
                  <div style={{ maxWidth: '400px'}}>
                    <SmartCaptcha sitekey={process.env.REACT_APP_YANDEX_CAPTCHA} onSuccess={setToken} />
                  </div>

                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}
