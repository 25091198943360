import VideoPrev from 'assets/images/content/video-preview.jpg'
import './SubTop.scss'

export const SubTop = () => {
  const videoUrl = process.env.REACT_APP_BACKEND.slice(0,-3) + 'video/sila-idei.mp4'
  return (
    <section id="subtop" className="subtop">
      <div className="container subtop__container">
        <div className="subtop__container_wrap">
          <div className="subtop__text">
            <div className="subtop__text_title">О конкурсе</div>
            <div className="subtop__text_descr">
              <p>
                Всероссийский конкурс гражданских инициатив&nbsp;&mdash; это
                конкурс для представителей некоммерческого сектора
                и&nbsp;лидеров общественных проектов.
              </p>
              <p>Мы ищем лучшие идеи для развития городов, сёл, регионов и страны!</p>
              <p>
                Конкурс поможет воплотить идеи в жизнь. Он предоставит возможность получить ресурсную и административную поддержку Партии, персональное обучение, бесплатную консультацию и помощь в написании заявки на один из конкурсов грантов на развитие НКО и проектов, помощь в реализации и масштабировании своих инициатив, а также информационную поддержку и возможность стать кандидатом в депутаты.
              </p>
              <p>Приглашаем всех лидеров присоединиться к&nbsp;конкурсу!</p>
            </div>
          </div>
          <div className="subtop__video">
            <video
              controls
              disablePictureInPicture
              controlsList="nodownload noremoteplayback noplaybackrate"
              poster={VideoPrev}
            >
              <source
                src={videoUrl}
                type="video/mp4"
                preload="none"
              />
            </video>
          </div>
        </div>
      </div>
    </section>
  )
}
