import { useEffect } from 'react'
import { useAuthState } from 'context'
import { MainLayout } from 'components/layout/mainLayout'
import { Bckgr } from 'blocks'
import axios from 'axios'
import { scroll } from 'components/utils'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { Loader } from 'components/loader/loader'
import { updateUser } from 'context/actions'
import { useAuthDispatch, logout } from 'context'
import BreadcrumbsProfile from 'components/breadcrumbsProfile'
import { ShowNomination } from 'components/forms/profile/showNomination'
import { Nomination } from 'components/forms/profile/nomination'

export const SendVote = (params) => {
  const { token, newUser } = useAuthState()
  const [loading, setLoading] = useState(true)
  const [region, setRegion] = useState([])
  const dispatch = useAuthDispatch()
  const [app, setApp] = useState([])
  const history = useHistory()

  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios
      .get(`${process.env.REACT_APP_BACKEND}/user/profile`)
      .then((data) => {
        data.data.data.application && setApp([data.data.data.application])
        updateUser(dispatch, data.data.data).then(() => setLoading(false))
        if(!data.data.data.email && newUser) {
          return history.push('/login')
        }

      })
      .catch(function (err) {
        logout(dispatch)
      })
  }, [token, dispatch, history, newUser])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/list/region`)
      .then((data) => setRegion(data.data))
  }, [])
  useEffect(() => {
    scroll('#root')
  }, [])
  if (loading) return <Loader />

  return (
    <MainLayout>
      <Bckgr />
      <section className="sendvote">
        <div className="container sendvote__container">
          <BreadcrumbsProfile
            nomination={params?.location?.query?.nomination}
          />
          {app.length > 0 ? (
            <ShowNomination region={region} nomination={app} />
          ) : (
            <Nomination
              region={region}
              nomination={params?.location?.query?.nomination}
            />
          )}
        </div>
      </section>
    </MainLayout>
  )
}

// todo: что выведется при уже отправленной заявке
