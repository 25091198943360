// import { Link } from 'react-router-dom'
// import { useAuthState } from 'context'
import './Nominations.scss'

export const Nominations = () => {
  // const { token } = useAuthState()

  return (
    <section id="nominations" className="nominations">
      <div className="container">
        <h2 className="nominations__title title">номинации и направления</h2>
        <div className="nominations__wrap">
          <div className="nominations__item">
            <div className="nominations__item_title">Лучшая федеральная инициатива</div>
            <div className="nominations__item_desc">
            Определяются лучшие инициативы на федеральном уровне для реализации в 2025-2026г.
            </div>
            {/* <div className="nominations__item_btn">
              <Link
                to={
                  token
                    ? {
                        pathname: '/vote/sendvote',
                        query: { nomination: 1, title: 'Лучшая федеральная инициатива' },
                      }
                    : {
                        pathname: '/login',
                        query: { vote_redir: true },
                      }
                }
              >
                <button className="top__btn button button_white">
                  Подать заявку
                </button>
              </Link>
            </div> */}
          </div>
          <div className="nominations__item">
            <div className="nominations__item_title">Лучшая региональная инициатива</div>
            <div className="nominations__item_desc">
            Определяются лучшие инициативы на региональном уровне для реализации в 2025-2026г.
            </div>
            {/* <div className="nominations__item_btn">
              <Link
                to={
                  token
                    ? {
                        pathname: '/vote/sendvote',
                        query: { nomination: 2, title: 'Лучшая региональная инициатива' },
                      }
                    : {
                        pathname: '/login',
                        query: { vote_redir: true },
                      }
                }
              >
                <button className="top__btn button button_white">
                  Подать заявку
                </button>
              </Link>
            </div> */}
          </div>
          <div className="nominations__item">
            <div className="nominations__item_title">Лучшая местная инициатива</div>
            <div className="nominations__item_desc">
            Определяются лучшие инициативы на местном уровне для реализации в 2025-2026г.
            </div>
            {/* <div className="nominations__item_btn">
              <Link
                to={
                  token
                    ? {
                        pathname: '/vote/sendvote',
                        query: { nomination: 3, title: 'Лучшая идея' },
                      }
                    : {
                        pathname: '/login',
                        query: { vote_redir: true },
                      }
                }
              >
                <button className="top__btn button button_white">
                  Подать заявку
                </button>
              </Link>
            </div> */}
          </div>
        </div>
        <div className='position__wrap'>
          <div className='position__item'>
            <div className='position__item_desc'>
            Сохранение исторической памяти и патриотическое воспитание
            </div>
          </div>
          <div className='position__item'>
            <div className='position__item_desc'>
            Социальное обслуживание, социальная поддержка и защита граждан
            </div>
          </div>
          <div className='position__item'>
            <div className='position__item_desc'>
            Охрана здоровья граждан, пропаганда здорового образа жизни
            </div>
          </div>
          <div className='position__item'>
            <div className='position__item_desc'>
            Поддержка семьи, материнства, отцовства и детства
            </div>
          </div>
          <div className='position__item'>
            <div className='position__item_desc'>
            Поддержка проектов в области науки, образования, просвещения
            </div>
          </div>
          <div className='position__item'>
            <div className='position__item_desc'>
            Поддержка проектов в области культуры и искусства
            </div>
          </div>
          <div className='position__item'>
            <div className='position__item_desc'>
            Охрана окружающей среды и защита животных
            </div>
          </div>
          <div className='position__item'>
            <div className='position__item_desc'>
            Воспитание детей и молодёжи
            </div>
          </div>
          <div className='position__item'>
            <div className='position__item_desc'>
            Укрепление межнационального и межрелигиозного согласия
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
