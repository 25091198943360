import React from "react";
import { withRouter } from "react-router-dom";
import { vkUser, logout } from 'context/actions'
import queryString from 'query-string';
import { useAuthDispatch } from '../../context'
import Vk from 'assets/images/svg/VK1.svg'


function dec2hex(dec) {
  return ('0' + dec.toString(16)).substr(-2)
}

function generateRandomString() {
  const array = new Uint32Array(56/2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}

function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

function base64urlencode(a) {
      let str = "";
      const bytes = new Uint8Array(a);
      let len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
      }
      return btoa(str)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    }

async function challenge_from_verifier(v) {
  const hashed = await sha256(v);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
}
const code_verifier = generateRandomString();
const code_challenge = await challenge_from_verifier(code_verifier);

const VKButton = (props) => {
  const host = `${window.location.href}`
  const {setPhone, setStep, setIsnew} = props
  const dispatch = useAuthDispatch()
  const state = generateRandomString()
  const handleRedirect = () => {
    window.location.href = `https://id.vk.com/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${host}&scope=vkid.personal_info%20email%20phone&code_challenge_method=S256&code_challenge=${code_challenge}&response_type=code&state=${state}`;
  };

  let queryObj = queryString.parse(props.location.search);
  React.useEffect(() => {
    const verify = sessionStorage.getItem('verify', code_verifier)
    const sessionState = sessionStorage.getItem('state', state)

    if(!verify) sessionStorage.setItem('verify', code_verifier)
    if(!sessionState) sessionStorage.setItem('state', state)

    if (queryObj["code"]) vkUser(dispatch, {...queryObj, code_verifier: verify, state: sessionState})
      .then((data) => {
        if(data) {
          setIsnew(data.is_new)
        setPhone(data.user?.phone)
      return  setStep(2)
      }
      return logout(dispatch)
      })
      .catch(e => console.log('here'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='authorization__vk' onClick={handleRedirect}>
        <img
          src={Vk}
          alt="vk logo"
          className={''}
        />
        
       авторизоваться через Вконтакте
      {/* {isError && <p style={{ color: "red" }}>Ошибка входа через ВК</p>} */}
    </div>
  );
};

export default withRouter(VKButton);
