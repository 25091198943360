import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { InputPhone } from 'components/inputs/phoneInput'
import { MainLayout } from 'components/layout/mainLayout'
import axios from 'axios'
import { Bckgr } from 'blocks'
import { Loader } from 'components/loader/loader'
import { FormSelect } from 'components/inputs/selectField'
import { validationProfile } from '../validation'
import { DateInput } from 'components/inputs/birthdayInput'
import { useHistory } from 'react-router-dom'
import BreadcrumbsProfile from 'components/breadcrumbsProfile'
import { formatDate2 } from 'components/utils'
import { useAuthState } from 'context'
import { ToastContainer } from 'react-toastify'
import { scroll } from 'components/utils'
export const ProfileStep = ({ phone, voteRedir }) => {
  const { token, userDetails } = useAuthState()
  const [selectedRegionOption, setSelectedRegionOption] = useState(null)
  const [selectedDisctrictOption, setSelectedDisctrictOption] = useState(null)
  const [regionOptions, setRegionOptions] = useState(null)
  const [loading, setLoading] = useState(true)
  const [region, setRegion] = useState([])
  const [date, setDate] = useState('')
  const history = useHistory()
  const districtRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_BACKEND}/list/region`)
      .then((data) => setRegion(data.data))
      .then((data) => setLoading(false))
  }, [])
  useEffect(() => {
    if (userDetails?.birthday) setDate(new Date(userDetails?.birthday))
    if (userDetails?.district_id) setSelectedDisctrictOption(userDetails?.district_id)
    if (userDetails?.region_id) setSelectedRegionOption(userDetails?.region_id)
console.log(userDetails,'USERDETAILS')
    console.log(selectedRegionOption)
    console.log(selectedDisctrictOption)
    scroll('#root')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, userDetails?.birthday, userDetails?.district_id, userDetails?.region_id])
  const selectRegion = (setValue) => (id) => {
    setSelectedRegionOption(+id)
    axios
      .get(`${process.env.REACT_APP_BACKEND}/list/region/${id}/district`)
      .then((data) => {
        setValue('district_id', '')
        setRegionOptions(data.data)
        setLoading(false)
      })
  }
  const getRegionOpt = () => {
    if (regionOptions)
      return regionOptions.data.map((e) => {
        return { value: e.id, label: e.name }
      })
  }
  const initialValues = {
    surname: userDetails?.surname ?? '',
    name: userDetails?.name ?? '',
    phone: phone,
    email: userDetails?.email ?? '',
    birthday: date,
    patronymic: userDetails?.patronymic,
    district_id: selectedDisctrictOption,
    region_id: selectedDisctrictOption,
    city: userDetails?.city ?? '',
    street: userDetails?.street ?? '',
    house: userDetails?.house ?? '',
    apartment: userDetails?.apartment ?? '',
    personal_data_confirmation: false,
  }
  return (
    <MainLayout>
      <Bckgr />
      {loading ? (
        <Loader />
      ) : (
        <section className="authorization">
          <div className="authorization__container">
            <h2 className="authorization__title title">Регистрация</h2>
            <BreadcrumbsProfile />
            <div className="authorization__step1">
              <Formik
                initialValues={{ ...initialValues }}
                validate={(values) => {
                  return validationProfile(values)
                }}
                onSubmit={(values, { setFieldError }) => {
                  setIsLoading(true)
                  const address =
                    (values?.region_id?.label ?? '') +
                    (values?.district_id?.label
                      ? ', городской округ ' + values.district_id.label
                      : '') +
                    (values?.city ? ', город ' + values.city : '') +
                    (values?.street ? ', улица ' + values.street : '') +
                    (values?.house ? ', д. ' + values.house : '') +
                    (values?.apartment ? ', кв. ' + values.apartment : '')
                  const prepData = {
                    ...values,
                    address: address,
                    birthday: formatDate2(date),
                    region_id: selectedRegionOption,
                    district_id: selectedDisctrictOption,
                  }
                  axios.defaults.headers.common[
                    'Authorization'
                  ] = `Bearer ${token}`
                  axios
                    .post(`${process.env.REACT_APP_BACKEND}/auth/register`, {
                      ...prepData,
                    })
                    .then((data) => {
                      return voteRedir
                        ? history.push('/vote')
                        : history.push('/profile')
                    })
                    .catch((e) => {
                      const { errors } = e.response.data
                      Object.keys(errors).forEach((key) => {
                        setFieldError(key, errors[key])
                        // TODO: Переделать модалки ошибок
                      })
                    })
                    .finally(() => setIsLoading(false))
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form
                    className="authorization__form authorization__form_reg js-registration-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="authorization__input-group triple">
                      <div className="authorization__input-block">
                        <label htmlFor="surname">Фамилия*</label>
                        <Field
                          className={errors.surname ? 'input error' : 'input'}
                          onChange={handleChange}
                          value={values.surname}
                          id="surname"
                          name="surname"
                          type="text"
                          placeholder="Фамилия"
                        />
                        <span className="authorization__error">
                          {errors.surname && touched.surname && errors.surname}
                        </span>
                      </div>
                      <div className="authorization__input-block">
                        <label htmlFor="name">Имя*</label>
                        <Field
                          onChange={handleChange}
                          value={values.name}
                          className={errors.name ? 'input error' : 'input'}
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Имя"
                        />
                        <span className="authorization__error">
                          {errors.name && touched.name && errors.name}
                        </span>
                      </div>
                      <div className="authorization__input-block">
                        <label htmlFor="patronymic">Отчество (если есть)</label>
                        <Field
                          onChange={handleChange}
                          value={values.patronymic}
                          className="input"
                          id="patronymic"
                          name="patronymic"
                          type="text"
                          placeholder="Отчество"
                        />
                        <span className="authorization__error">
                          {errors.patronymic}
                        </span>
                      </div>
                    </div>
                    <div className="authorization__input-group triple">
                      <div className="authorization__input-block">
                        <label htmlFor="birthday">Дата рождения*</label>
                        <Field
                          className={
                            !!errors.birthday
                              ? 'input datepicker error'
                              : 'input datepicker'
                          }
                          id="birthday"
                          component={DateInput}
                          clearIcon={null}
                          calendarIcon={null}
                          monthPlaceholder={'ММ'}
                          dayPlaceholder={'ДД'}
                          yearPlaceholder={'ГГГГ'}
                          onChange={setDate}
                          value={date}
                          name="birthday"
                          type="text"
                          placeholder="ДД.ММ.ГГГГ"
                        />
                        <span className="authorization__error">
                          {errors.birthday &&
                            touched.birthday &&
                            errors.birthday}
                        </span>
                      </div>
                      <div className="authorization__input-block">
                        <label htmlFor="phone">Номер телефона*</label>
                        <InputPhone
                          onChange={handleChange}
                          onBlur={handleBlur}
                          readOnly
                          className={errors.phone ? 'input error' : 'input'}
                          disabled={true}
                          value={values.phone}
                        />
                      </div>
                      <div className="authorization__input-block">
                        <label htmlFor="email">Адрес электронной почты*</label>
                        <Field
                          className={errors.email ? 'input error' : 'input'}
                          id="email"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                          type="text"
                          placeholder="Email"
                        />
                        <span className="authorization__error">
                          {errors.email && touched.email && errors.email}
                        </span>
                      </div>
                    </div>
                    <div className="authorization__input-group triple">
                      <div className="authorization__input-block">
                        <label>Регион*</label>
                        <Field
                          name="region_id"
                          component={FormSelect}
                          className={
                            errors.region_id ? 'select error' : 'select'
                          }
                          value={selectedRegionOption}
                          placeholder="Выберите"
                          onChange={selectRegion(setFieldValue)}
                          options={region.data.map((e) => {
                            return { value: e.id, label: e.name }
                          })}
                        />
                        <span className="authorization__error">
                          {errors.region_id &&
                            touched.region_id &&
                            errors.region_id}
                        </span>
                      </div>
                      <div className="authorization__input-block">
                        <label htmlFor="district_id">Район*</label>
                        <Field
                          ref={districtRef}
                          name="district_id"
                          className={
                            errors.district_id ? 'select error' : 'select'
                          }
                          component={FormSelect}
                          placeholder="Выберите"
                          options={getRegionOpt()}
                          value={selectedDisctrictOption}
                          onChange={setSelectedDisctrictOption}
                          disabled={!values.region_id}
                        />
                        <span className="authorization__error">
                          {errors.district_id &&
                            touched.district_id &&
                            errors.district_id}
                        </span>
                      </div>
                      <div className="authorization__input-block">
                        <label htmlFor="city">Населенный пункт*</label>
                        <Field
                          className={errors.city ? 'input error' : 'input'}
                          id="city"
                          onChange={handleChange}
                          value={values.city}
                          name="city"
                          type="text"
                          placeholder="Населенный пункт"
                        />
                        <span className="authorization__error">
                          {errors.city && touched.city && errors.city}
                        </span>
                      </div>
                    </div>
                    <div className="authorization__input-group triple">
                      <div className="authorization__input-block">
                        <label htmlFor="street">Улица*</label>
                        <Field
                          className={errors.street ? 'input error' : 'input'}
                          onChange={handleChange}
                          value={values.street}
                          id="street"
                          name="street"
                          type="text"
                          placeholder="Улица"
                        />
                        <span className="authorization__error">
                          {errors.street && touched.street && errors.street}
                        </span>
                      </div>
                      <div className="authorization__input-block">
                        <label htmlFor="house">Дом*</label>
                        <Field
                          onChange={handleChange}
                          value={values.house}
                          className={errors.house ? 'input error' : 'input'}
                          id="house"
                          name="house"
                          type="text"
                          placeholder="Дом"
                        />
                        <span className="authorization__error">
                          {errors.house && touched.house && errors.house}
                        </span>
                      </div>
                      <div className="authorization__input-block">
                        <label htmlFor="apartment">Квартира</label>
                        <Field
                          onChange={handleChange}
                          value={values.apartment}
                          className="input"
                          id="apartment"
                          name="apartment"
                          type="text"
                          placeholder="Квартира"
                        />
                        <span className="authorization__error">
                          {errors.apartment}
                        </span>
                      </div>
                    </div>
                    <div className="authorization__input-group">
                      <div className="authorization__checkbox-block">
                        <label htmlFor="personal_data_confirmation">
                          <Field
                            id="personal_data_confirmation"
                            name="personal_data_confirmation"
                            type="checkbox"
                            onChange={handleChange}
                          />
                          <span>
                            Я даю{' '}
                            <a
                              target={'_blank'}
                              rel="noreferrer"
                              href="https://er.ru/upages/personal"
                            >
                              согласие на обработку{' '}
                            </a>
                            моих персональных данных в соответствии ФЗ №152-ФЗ
                            «О персональных данных» и на
                            <a
                              target={'_blank'}
                              rel="noreferrer"
                              href="https://er.ru/upages/privacy-policy"
                            >
                              {' '}
                              Политику конфиденциальности
                            </a>
                          </span>
                        </label>
                        <span className="authorization__error">
                          {errors.personal_data_confirmation &&
                            touched.personal_data_confirmation &&
                            errors.personal_data_confirmation}
                        </span>
                      </div>
                    </div>
                    <div className="authorization__input-button to-right">
                      <button
                        className="authorization__btn-submit button"
                        type="submit"
                      >
                        {isLoading && <Loader />}
                        <span style={{ opacity: isLoading ? 0 : 1 }}>
                          Зарегистрироваться
                        </span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <ToastContainer />
        </section>
      )}
    </MainLayout>
  )
}
